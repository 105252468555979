import { inject, Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticateAccessService } from './authenticate-access.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateAccessResolver {

  private _authAccessSrv = inject(AuthenticateAccessService);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const code = route.queryParams.code;
    const session_state = route.queryParams.session_state;
    const keycloakHost = route.queryParams.iss;

    return this._authAccessSrv.changeAutenticateCode(keycloakHost, code, session_state);
  }
}
