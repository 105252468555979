import { inject } from '@angular/core';
import { forkJoin } from 'rxjs';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';

export const InitialDataResolver = () =>  {

    const notificationsService = inject(NotificationsService);

    return forkJoin([
        notificationsService.getAll(),
    ]);
}
