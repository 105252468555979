import { inject } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ReportsAutonomousService } from './reports-autonomous.service';

export const ReportsAutonomousResolver = () =>  {

  const reportAutonomousService = inject(ReportsAutonomousService);

    return forkJoin([
      reportAutonomousService.list(),
    ]);

}
