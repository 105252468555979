import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthenticateAccessGuard } from './modules/auth/authenticate-access/authenticate-access.guard';
import { AuthenticateAccessResolver } from './modules/auth/authenticate-access/authenticate-access.resolver';
import { EmbarcadorGuard } from './core/auth/guards/embarcador.guard';
import { ReportGuard } from './modules/pages/reports/report.guard';
import { ReportResolver } from './modules/pages/reports/report.resolver';
import { JodaxGuard } from './core/auth/guards/jodax.guard';
import { EmbarcadorAutonomousGuard } from './core/auth/guards/embarcador-autonomous.guard';
import { EmbarcadorGeneralGuard } from './core/auth/guards/embarcador-general.guard';
import { ReportsAutonomousResolver } from './modules/pages/reports-autonomous/reports-autonomous.resolver';

export const appRoutes: Route[] = [
    {path: '', pathMatch : 'full', redirectTo: 'sign-in'},
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routing')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routing')},
            {path: 'reset-password/:token', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routing')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routing')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routing')}
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routing')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routing')}
        ]
    },
    {
        path: 'authenticate',
        canActivate: [AuthenticateAccessGuard],
        resolve: {initialData: AuthenticateAccessResolver},
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: '', loadChildren: () => import('app/modules/auth/authenticate-access/authenticate-access-routing.module')},
        ]
    },
    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routing')},
        ]
    },
    {
        path       : '',
        canActivate: [AuthGuard, EmbarcadorGuard],
        component  : LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve    : {
            initialData: InitialDataResolver,
            initData: ReportResolver
        },
        children   : [
            {path: 'dashboard', loadChildren: () => import('app/modules/pages/reports/report-routing.module')}
        ]
    },
    {
        path       : 'cadastro',
        canActivate: [AuthGuard, EmbarcadorGeneralGuard],
        component  : LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            { path: '', redirectTo: 'storage', pathMatch: 'full'},
            {path: 'embarcadora', canActivate: [JodaxGuard], loadChildren: () => import('app/modules/pages/cadastros/embarcadora/embarcadora-routing.module')},
            {path: 'produtos', loadChildren: () => import('app/modules/pages/cadastros/produtos/produtos-routing.module')},
            {path: 'storage', loadChildren: () => import('app/modules/pages/cadastros/armazens/armazens-routing.module')},
            {path: 'rules', loadChildren: () => import('app/modules/pages/cadastros/rules/rules-routing.module')},
        ]
    },
    {
        path       : 'order',
        canActivate: [AuthGuard, EmbarcadorGuard],
        component  : LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'storage', loadChildren: () => import('app/modules/pages/orders/storage/storage-order-routing.module')},
            {path: 'picking', loadChildren: () => import('app/modules/pages/orders/picking/picking-order-routing.module')},
        ]
    },
    {
        path       : 'order-autonomous',
        canActivate: [AuthGuard, EmbarcadorAutonomousGuard],
        component  : LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'autonomous-production', loadChildren: () => import('app/modules/pages/autonomous-production/autonomous-production-routing.module')},
            {path: 'autonomous-picking', loadChildren: () => import('app/modules/pages/autonomous-picking/autonomous-picking-routing.module')},
        ]
    },
    {
        path       : 'autonomous-inventory',
        canActivate: [AuthGuard, EmbarcadorAutonomousGuard],
        component  : LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: '', loadChildren: () => import('app/modules/pages/autonomous-inventory/autonomous-inventory-routing.module')},
        ]
    },
    {
        path       : 'operation',
        canActivate: [AuthGuard, EmbarcadorGuard],
        component  : LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve    : {
            initialData: InitialDataResolver
        },
        children   : [
            {path: '', loadChildren: () => import('app/modules/pages/operation/operation-routing.module')},
        ]
    },
    {
        path       : 'operation-autonomous',
        canActivate: [AuthGuard, EmbarcadorAutonomousGuard],
        component  : LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve    : {
            initialData: InitialDataResolver
        },
        children   : [
            {path: 'autonomous-production', loadChildren: () => import('app/modules/pages/autonomous-production-report/autonomous-production-report-routing.module')},
            {path: 'autonomous-order', loadChildren: () => import('app/modules/pages/autonomous-order-report/autonomous-order-report-routing.module')},
            {path: 'autonomous-picking', loadChildren: () => import('app/modules/pages/autonomous-picking-report/autonomous-picking-report-routing.module')},
        ]
    },
    {
        path       : 'dashboard-autonomous',
        component  : LayoutComponent,
        data: {
            layout: 'modern'
        },
        canActivate: [AuthGuard, EmbarcadorAutonomousGuard, ReportGuard],
        resolve: {
            initialData: InitialDataResolver,
            initData: ReportsAutonomousResolver
        },
        children   : [
            {path: '', loadChildren: () => import('app/modules/pages/reports-autonomous/reports-autonomous-routing.module')}
        ]
    },
    {
        path       : 'reports',
        canActivate: [AuthGuard, EmbarcadorAutonomousGuard, ReportGuard],
        resolve: {
            initialData: InitialDataResolver,
            initData: ReportResolver
        },
        component  : LayoutComponent,
        data: {
            layout: 'modern'
        },
        children   : [
            {path: '', loadChildren: () => import('app/modules/pages/reports/report-routing.module')}
        ]
    },
    {
        path       : 'administration',
        canActivate: [AuthGuard, JodaxGuard],
        resolve: {
            initialData: InitialDataResolver,
            initData: ReportResolver
        },
        component  : LayoutComponent,
        data: {
            layout: 'modern'
        },
        children   : [
            {path: 'closure', loadChildren: () => import('app/modules/pages/fechamento/fechamento-routing.module')}
        ]
    }
];
