import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from 'app/mock-api/common/navigation/data';
import { defaultOperationNavigation, futuristicOperationNavigation, horizontalOperationNavigation } from './data-operation';
import { defCliNavigation, defCliNavigationUs } from './data-client';
import { compactAdminNavigation, defaultAdminNavigation, futuristicAdminNavigation, horizontalAdminNavigation } from './data-admin';
import { defClientAutonomousNav, defClientAutonomousNavUs } from './data-client-autonomous';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class NavigationMockApi {

    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    private readonly _compactAdminNavigation: FuseNavigationItem[] = compactAdminNavigation;
    private readonly _defaultAdminNavigation: FuseNavigationItem[] = defaultAdminNavigation;
    private readonly _futuristicAdminNavigation: FuseNavigationItem[] = futuristicAdminNavigation;
    private readonly _horizontalAdminNavigation: FuseNavigationItem[] = horizontalAdminNavigation;

    private readonly _defCliNavigation: FuseNavigationItem[] = defCliNavigation;
    private readonly _defCliNavigationUs: FuseNavigationItem[] = defCliNavigationUs;

    private readonly _defCliAutonomousNav: FuseNavigationItem[] = defClientAutonomousNav;
    private readonly _defCliAutonomousNavUs: FuseNavigationItem[] = defClientAutonomousNavUs;

    private readonly _compactOperationNavigation: FuseNavigationItem[] = defaultOperationNavigation;
    private readonly _defaultOperationNavigation: FuseNavigationItem[] = defaultOperationNavigation;
    private readonly _futuristicOperationNavigation: FuseNavigationItem[] = futuristicOperationNavigation;
    private readonly _horizontalOperationNavigation: FuseNavigationItem[] = horizontalOperationNavigation;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet('api/common/navigation').reply(() => {
            // Fill compact navigation children using the default navigation
            this._compactNavigation.forEach((compactNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === compactNavItem.id) {
                        compactNavItem.children = cloneDeep(
                            defaultNavItem.children
                        );
                    }
                });
            });

            // Fill futuristic navigation children using the default navigation
            this._futuristicNavigation.forEach((futuristicNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === futuristicNavItem.id) {
                        futuristicNavItem.children = cloneDeep(
                            defaultNavItem.children
                        );
                    }
                });
            });

            // Fill horizontal navigation children using the default navigation
            this._horizontalNavigation.forEach((horizontalNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === horizontalNavItem.id) {
                        horizontalNavItem.children = cloneDeep(
                            defaultNavItem.children
                        );
                    }
                });
            });

            // Return the response
            return [
                200,
                {
                    compact: cloneDeep(this._compactNavigation),
                    default: cloneDeep(this._defaultNavigation),
                    futuristic: cloneDeep(this._futuristicNavigation),
                    horizontal: cloneDeep(this._horizontalNavigation),
                },
            ];
        });

        // Menu de Admin
        this._fuseMockApiService.onGet('api/common/navigation/admin').reply(() => {

            // Fill compact navigation children using the default navigation
            this._compactAdminNavigation.forEach((compactNavItem) => {
                this._defaultAdminNavigation.forEach((defaultNavItem) => {
                    if ( defaultNavItem.id === compactNavItem.id )
                    {
                        compactNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill futuristic navigation children using the default navigation
            this._futuristicAdminNavigation.forEach((futuristicNavItem) => {
                this._defaultAdminNavigation.forEach((defaultNavItem) => {
                    if ( defaultNavItem.id === futuristicNavItem.id )
                    {
                        futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill horizontal navigation children using the default navigation
            this._horizontalAdminNavigation.forEach((horizontalNavItem) => {
                this._defaultAdminNavigation.forEach((defaultNavItem) => {
                    if ( defaultNavItem.id === horizontalNavItem.id )
                    {
                        horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Return the response
            return [
                200,
                {
                    compact   : cloneDeep(this._compactAdminNavigation),
                    default   : cloneDeep(this._defaultAdminNavigation),
                    futuristic: cloneDeep(this._futuristicAdminNavigation),
                    horizontal: cloneDeep(this._horizontalAdminNavigation)
                }
            ];
        });

        // Menu de client
        this._fuseMockApiService.onGet('api/common/navigation/client').reply((r) => {

            const [key, value] = r.request.params.toString().split('=');
            let navDefault: any;
            switch (value) {
                case 'en': navDefault = cloneDeep(this._defCliNavigationUs); break;
                default: navDefault = cloneDeep(this._defCliNavigation); break;
            }
            return [200, { default: navDefault }];
        });

        // Menu de client Autonomous
        this._fuseMockApiService.onGet('api/common/navigation/client-autonomous').reply((r) => {

            const [key, value] = r.request.params.toString().split('=');
            let navDefault: any;
            switch (value) {
                case 'en': navDefault = cloneDeep(this._defCliAutonomousNavUs); break;
                default: navDefault = cloneDeep(this._defCliAutonomousNav); break;
            }
            return [200, { default: navDefault }];
        });

        // Menu de operador
        this._fuseMockApiService.onGet('api/common/navigation/operation').reply(() => {

            // Fill compact navigation children using the default navigation
            this._compactOperationNavigation.forEach((compactNavItem) => {
                this._defaultOperationNavigation.forEach((defaultNavItem) => {
                    if ( defaultNavItem.id === compactNavItem.id )
                    {
                        compactNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill futuristic navigation children using the default navigation
            this._futuristicOperationNavigation.forEach((futuristicNavItem) => {
                this._defaultOperationNavigation.forEach((defaultNavItem) => {
                    if ( defaultNavItem.id === futuristicNavItem.id )
                    {
                        futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill horizontal navigation children using the default navigation
            this._horizontalOperationNavigation.forEach((horizontalNavItem) => {
                this._defaultOperationNavigation.forEach((defaultNavItem) => {
                    if ( defaultNavItem.id === horizontalNavItem.id )
                    {
                        horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Return the response
            return [
                200,
                {
                    compact   : cloneDeep(this._compactOperationNavigation),
                    default   : cloneDeep(this._defaultOperationNavigation),
                    futuristic: cloneDeep(this._futuristicOperationNavigation),
                    horizontal: cloneDeep(this._horizontalOperationNavigation)
                }
            ];
        });

    }
}
