/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { environment } from 'environments/environment';
import { jwtDecode } from 'jwt-decode';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateAccessService {

  private api = environment.api;
  private api_backend = environment.api_backend;
  private api_keyAuth = environment.api_keyAuth;

  constructor(
    private _httpClient: HttpClient
    )
  {}

  authenticateAccess(token: string, bearer: string): any
  {
    const bearerToken = 'Bearer ' + bearer;
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Authorization': bearerToken
    });
    return this._httpClient.get(this.api + `/auth/authenticate-module/${token}`, {headers: header})
    .pipe(
      map(() => {
        AuthUtils.setCookie({name: '_atk_pu', value: bearer, expireDays: 2});
        AuthUtils.setCookie({name: '_rtk_pu', value: bearer, expireDays: 2});
        return true;
      }),
      catchError(() => {
        AuthUtils.deleteCookie('_atk_pu');
        AuthUtils.deleteCookie('_rtk_pu');
        AuthUtils.deleteCookie('_us_pu');
        return of(false);
      })
    );
  }

  changeAutenticateCode(keycloakHost: string, code: string, session_state: string): any
  {
    const redirect_uri = environment.redirect_uri;
    const bodyParams = new URLSearchParams({
      client_id: 'pickingup',
      grant_type: 'authorization_code',
      code: code.toString(),
      redirect_uri: redirect_uri
    });
    const url = `${keycloakHost}/protocol/openid-connect/token`;
    const header = { 'Content-type': 'application/x-www-form-urlencoded' };

    return this._httpClient.post(url, bodyParams.toString(), {headers: header}).pipe(
      map((result: any) => {
        const access_token = result.access_token;
        const refresh_token = result.refresh_token;
        const idToken = result.id_token;
        const idTokenData: any = jwtDecode(idToken);
        const dados: any = this._decodeJwt(access_token);
        const pickingUpRole = dados.pickingup.roles;
        if (!pickingUpRole.includes('pickingup_admin_access')) {
          console.error('Você não tem permissão de acesso a este recurso');
          return of(false);
        } else {
          idTokenData['roles'] = pickingUpRole;
          AuthUtils.setCookie({name: '_atk_pu', value: access_token});
          AuthUtils.setCookie({name: '_rtk_pu', value: refresh_token});
          AuthUtils.setCookie({name: '_us_pu', value: JSON.stringify(idTokenData)});
          return idTokenData;
        }
      }),
      catchError((err) => {
        console.log(err)
        AuthUtils.deleteCookie('_atk_pu');
        AuthUtils.deleteCookie('_rtk_pu');
        AuthUtils.deleteCookie('_us_pu');
        return of(false);
      })
    );

  }

  private _decodeJwt(accessToken: string) {
    const data: any = jwtDecode(accessToken);
    const pickingupRole = data.resource_access['pickingup'];
    delete data.aud;
    delete data.scope;
    delete data.realm_access;
    delete data.resource_access;
    data['pickingup'] = pickingupRole;
    return data;
  }
}
