@if (ambient != 'production') {
    <div class="ribbon ribbon-top-left">
        @if (ambient === 'staging') {
            <span [ngClass]="{'ribbon-blue': ambient === 'staging'}">STAGING {{version}}</span>
        }
        @if (ambient === 'development') {
            <span [ngClass]="{'ribbon-green': ambient === 'development'}">HOMOLOGAÇÃO {{version}}</span>
        }
        @if (ambient === 'local') {
            <span [ngClass]="{'ribbon-red': ambient === 'local'}">LOCAL {{version}}</span>
        }
    </div>
}
<router-outlet></router-outlet>
