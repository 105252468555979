import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, Observable} from 'rxjs';
import { ReportService } from './report.service';

@Injectable({
  providedIn: 'root'
})
export class ReportResolver  {

  constructor(
    private _reportService: ReportService
  )
  { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin([
      this._reportService.list()
    ]);
  }
}
