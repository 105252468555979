import { NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { AvailableLangs, TranslocoService } from '@jsverse/transloco';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet],
})
export class LanguagesComponent implements OnInit, OnDestroy {
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;
    clientType: string;
    clientCategory: string;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _navigationSrv: NavigationService,
        private _translocoService: TranslocoService,
        private _userService: UserService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get client type
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                if (user) {
                    this.clientType = user.company.clientType;
                    this.clientCategory = user.company.clientCategory;
                }
                this._changeDetectorRef.markForCheck();
            });
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {
            // Get the active lang
            this.activeLang = activeLang;

            // Update the navigation
            this._updateNavigation(activeLang);
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            br: 'br',
            en: 'us',
        };
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {
        // Set the active lang
        this._translocoService.setActiveLang(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {


        if(['JODAX'].includes(this.clientType)) {
            this._navigationSrv.getAdmin().subscribe();
        } else if (['EMBARCADOR','FACTORY','INDUSTRY','SHIPPER'].includes(this.clientType)) {
            if(['AUTONOMOUS'].includes(this.clientCategory)) {
                this._navigationSrv.getClientAutonomous(lang).subscribe();
            } else if (['REGULAR','NORMAL','MANUAL'].includes(this.clientCategory)) {
                this._navigationSrv.getClient(lang).subscribe();
            }
        }

        // // Get the component -> navigation data -> item
        // const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
        // // Return if the navigation component does not exist
        // if (!navComponent) { return null; }
        // // Get the flat navigation data
        // const navigation = navComponent.navigation;
        // // Get the Project dashboard item and update its title
        // const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);

        // if (projectDashboardItem) {
        //     this._translocoService
        //         .selectTranslate('Project')
        //         .pipe(take(1))
        //         .subscribe((translation) => {
        //             // Set the title
        //             projectDashboardItem.title = translation;

        //             // Refresh the navigation component
        //             navComponent.refresh();
        //         });
        // }

        // // Get the Analytics dashboard item and update its title
        // const analyticsDashboardItem = this._fuseNavigationService.getItem(
        //     'dashboards.analytics',
        //     navigation
        // );
        // if (analyticsDashboardItem) {
        //     this._translocoService
        //         .selectTranslate('Analytics')
        //         .pipe(take(1))
        //         .subscribe((translation) => {
        //             // Set the title
        //             analyticsDashboardItem.title = translation;

        //             // Refresh the navigation component
        //             navComponent.refresh();
        //         });
        // }
    }
}
