import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateAccessGuard {

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      if(
        (route.queryParams.code && route.queryParams.code !== undefined) &&
        (route.queryParams.session_state && route.queryParams.session_state !== undefined)
      ) {
        return true;
      }
      return false;
  }
}
