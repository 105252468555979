import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Observable, catchError, throwError } from 'rxjs';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {

    const authService = inject(AuthService);

    // Clone the request object
    let newReq = req.clone();
    // Request
    if (
        !req.url.includes('assets') &&
        !req.url.includes('ibge') &&
        !req.url.includes('oauth') &&
        !req.url.includes('viacep') &&
        !req.url.includes('nominatim') &&
        !req.url.includes('openstreetmap') &&
        !req.url.includes('s3') &&
        !req.url.includes('reset-password') &&
        !req.url.includes('mapbox'))
    {
        if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken)) {
            newReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken) });
        }
        if (req.url.includes('uploads')) {
            newReq = req.clone({
                headers: req.headers.set('Content-type', '; charset=utf-8; boundary=' + Math.random().toString())
            });
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken)
            });
        }
    }

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Sign out
                authService.signOut();

                // Reload the app
                location.reload();
            }

            return throwError(error);
        })
    );
};
